import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledInvestmentPortfolios = styled(Box)(({ theme }) => ({

}))

const InvestmentPortfolios = () => {
	return (
		<StyledInvestmentPortfolios>
			InvestmentPortfolios
		</StyledInvestmentPortfolios>
	)
}

export default InvestmentPortfolios