import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledLearn = styled(Box)(({ theme }) => ({
	
}))

const Learn = () => {
	return (
		<StyledLearn>
			<div>Learn</div>
		</StyledLearn>
	)
}

export default Learn