import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledTopDownDagram = styled(Box)(({ theme }) => ({
	
}))

const TopDownDagram = () => {
	return (
		<StyledTopDownDagram>
			<div>TopDownDagram</div>
		</StyledTopDownDagram>
	)
}

export default TopDownDagram