import SingleNewsBanner from './sections/banner'
import NewsBody from './sections/body'



const SingleNews = () => {
	return (
		<>
			<SingleNewsBanner/>
			<NewsBody/>
		</>
	)
}


export default SingleNews