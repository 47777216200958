import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledInnovation = styled(Box)(({ theme }) => ({
	
}))

const Innovation = () => {
	return (
		<StyledInnovation>
			<div>Innovation</div>
		</StyledInnovation>
	)
}

export default Innovation