import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledInvestmentResources = styled(Box)(({ theme }) => ({
	
}))

const InvestmentResources = () => {
	return (
		<StyledInvestmentResources>
			<div>InvestmentResources</div>
		</StyledInvestmentResources>
	)
}

export default InvestmentResources