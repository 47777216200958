import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledInvestmentStructuredProducts = styled(Box)(({ theme }) => ({

}))

const InvestmentStructuredProducts = () => {
	return (
		<StyledInvestmentStructuredProducts>
			InvestmentStructuredProducts
		</StyledInvestmentStructuredProducts>
	)
}

export default InvestmentStructuredProducts