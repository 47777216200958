import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledResearch = styled(Box)(({ theme }) => ({
	
}))

const Research = () => {
	return (
		<StyledResearch>
			<div>Research</div>
		</StyledResearch>
	)
}

export default Research